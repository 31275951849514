import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => {
	return (
		<Layout>
			<SEO title="Thank you for contacting us" />
			<h1 className="text-gray-900">Thank you!</h1>
			<Link
				className="font-semibold text-purple-600 hover:text-purple-500 italic"
				to="/"
			>
				<p>Back to home</p>
			</Link>
		</Layout>
	)
}

export default ThankYou
